// eslint-disable-next-line import/prefer-default-export
export const Permissions = [
  'account_admins',
  'adobe_conversion',
  'advanced_search',
  'admins',
  'allow_moments',
  'analytics',
  'api',
  'api_limit',
  'assets_storage_limit',
  'audit_logs',
  'card_cta',
  'content_panels',
  'custom_fonts',
  'disable_ga',
  'disable_hashtag',
  'discover',
  'embed',
  'embed_analytics',
  'embed_cycle',
  'embed_layouts',
  'embed_post_analytics',
  'embeds',
  'fcc_filter',
  'fb_live_pages',
  'graphics',
  'interactive_1_0',
  'interactive_1_1',
  'keyword_filter',
  'loaded_post_networks',
  'loaded_posts',
  'live',
  'manual_feature',
  'moderation',
  'num_feeds',
  'num_posts',
  'num_tagboards',
  'opta_google_sheets',
  'panels',
  'presentation_mode',
  'premium_panels',
  'qr_code_analytics',
  'qr_code_scans',
  'random_content_selector',
  'secondary_hashtags',
  'share_graphics',
  'share_themes',
  'sheet_data_element',
  'shoppable',
  'shoppable_amazon',
  'sortable_featured',
  'sportradar_element',
  'stats_perform_google_sheets',
  'tagboard_launch',
  'touch',
  'twilio',
  'user_consent',
  'user_feeds',
  'user_notifications',
  'video_element',
  'visibility',
];
