import { useStorage } from '@vueuse/core';
import { ref } from 'vue';

const themePreference = useStorage(
  'tagboard-global',
  {
    themePreference: 'os', // Defaults to 'OS'
  },
);

const userPrefersDark = ref(false);

function applyTheme(theme) {
  // todo: replace these themes with our own self hosted
  const darkLink = '/themes/taglandia-dark/theme.css';
  const lightLink = '/themes/taglandia-light/theme.css';
  const bodyClassList = document.body.classList;
  userPrefersDark.value = theme === 'dark' || (theme === 'os' && window.matchMedia('(prefers-color-scheme: dark)').matches);

  if (userPrefersDark.value) {
    bodyClassList.add('dark-theme');
    bodyClassList.remove('light-theme');
  } else {
    bodyClassList.remove('dark-theme');
    bodyClassList.add('light-theme');
  }

  const themeHref = userPrefersDark.value ? darkLink : lightLink;

  let themeLink = document.getElementById('app-theme');

  if (themeLink) {
    themeLink.href = themeHref;
  } else {
    themeLink = document.createElement('link');
    themeLink.id = 'app-theme';
    themeLink.rel = 'stylesheet';
    themeLink.href = themeHref;
    document.head.appendChild(themeLink);
  }
}

export default () => ({
  themePreference,
  applyTheme,
  userPrefersDark,
});
