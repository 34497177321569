import { createFetch } from '@vueuse/core';
import { computed } from 'vue';
import useErrorToasts from '@/composables/useErrorToasts';
import {
  ADOBE_API, LAMBDA_AUTH_HEADER, LAMBDA_AUTH_HEADER_VALUE,
} from '@/config/appConfig';

import useAuthStore from '@/stores/auth';

const adobeFetch = createFetch({
  baseUrl: ADOBE_API,
  combination: 'overwrite',
  options: {
    async onFetchError(payload) {
      useErrorToasts(payload);
      return payload;
    },
    beforeFetch({ options, cancel }) {
      const authStore = useAuthStore();

      if (!authStore.currentTeamId) cancel();
      // eslint-disable-next-line no-param-reassign
      options.headers['Tgb-Token'] = authStore.userToken;
    },
  },
  fetchOptions: {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      [LAMBDA_AUTH_HEADER]: LAMBDA_AUTH_HEADER_VALUE,
    },
  },
});

export default () => ({
  getAdobeUploadLink: (userId, query, options = {}) => {
    const queryString = computed(() => Object.keys(query.value).map((k) => `${k}=${query.value[k]}`).join('&'));
    const url = computed(() => `psd/uploadLink/${userId.value}?${queryString.value}`);

    return adobeFetch(url, options).get().json();
  },
  getProcessStatus: (userId, options = {}) => {
    const url = computed(() => `psd/conversions?userId=${userId.value}`);

    return adobeFetch(url, options).get().json();
  },
  makePusherAuth: (payload, options = {}) => {
    const url = 'pusher/auth';

    return adobeFetch(url, options).post(payload).json();
  },
});
