export const ADOBE_API = import.meta.env.VITE_ADOBE_API;
export const ANALYTICS_URL = import.meta.env.VITE_ANALYTICS_URL;
export const ASSET_MANAGER_API = import.meta.env.VITE_ASSET_MANAGER_API;
export const MEDIA_URL = import.meta.env.VITE_MEDIA_URL;
export const ASSETS_CDN_URL = import.meta.env.VITE_ASSETS_CDN_URL;
export const BOARDS_API = import.meta.env.VITE_BOARDS_API;
export const DASHBOARD_URL = import.meta.env.VITE_DASHBOARD_URL;
export const EMBED_API = import.meta.env.VITE_EMBED_API;
export const EVENT_LOGGER_API = import.meta.env.VITE_EVENT_LOGGER_API;
export const GRAPHICS_API = import.meta.env.VITE_GRAPHICS_API;
export const LIVE_API = import.meta.env.VITE_LIVE_API;
export const PANELS_API = import.meta.env.VITE_PANELS_API;
export const PLANS_API = import.meta.env.VITE_PLANS_API;
export const PRODUCER_URL = import.meta.env.VITE_PRODUCER_URL;
export const PAGES_API = import.meta.env.VITE_PAGES_API;
export const PAGES_URL = import.meta.env.VITE_PAGES_URL;
export const POST_BROKER_API = import.meta.env.VITE_POST_BROKER_BASE;
export const PUSHER_KEY = import.meta.env.VITE_PUSHER_KEY;
export const PUSHER_CLUSTER = import.meta.env.VITE_PUSHER_CLUSTER;
export const SHORT_URL_API = import.meta.env.VITE_SHORT_URL_API;
export const SPORTRADAR_API = import.meta.env.VITE_SPORTRADAR_API;
export const TASHBOARD_URL = import.meta.env.VITE_TASHBOARD_URL;
export const USER_API = import.meta.env.VITE_USER_API;
export const USERPILOT_TOKEN = import.meta.env.VITE_USERPILOT_TOKEN;
export const INTERACT_API = import.meta.env.VITE_INTERACT_API;
export const TGB_API = import.meta.env.VITE_TGB_API;
export const STORY_API = import.meta.env.VITE_STORY_API;

export const API_BASE = import.meta.env.VITE_API_BASE;

export const BASE_URL = import.meta.env.VITE_BASE_URL;
export const BASE_DOMAIN = import.meta.env.VITE_DOMAIN;

export const LAMBDA_AUTH_HEADER = import.meta.env.VITE_LAMBDA_AUTH_HEADER;
export const LAMBDA_AUTH_HEADER_VALUE = import.meta.env.VITE_LAMBDA_AUTH_HEADER_VALUE;

export const LAUNCH_DARKLY_CLIENT_ID = import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID;

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const SENTRY_TRACES_SAMPLE_RATE = import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE;

export const NODE_ENV = import.meta.env.VITE_NODE_ENV;
export const COMMIT_HASH = import.meta.env.VITE_COMMIT_HASH;

export const MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;
