import { defineStore } from 'pinia';
import { ref } from 'vue';

const useToastsStore = defineStore('toasts', () => {
  // Do we want to extract internal data like this to it's own area?
  // feels ok for now, but might gum up the readability of this file overtime if
  // we have more of these
  const errorMessagesByCode = {
    400: {
      severity: 'error',
      summary: 'Bad request',
      detail: 'Oops, we couldn\'t quite catch that. Give it another shot. If the issue persists, our support team\'s got your back.',
    },
    401: {
      severity: 'error',
      summary: 'Access denied',
      detail: 'Whoopsie! You need to log in for this. Don\'t worry, we\'ll get you sorted. Log in or sign up, and you\'re good to go!',
    },
    403: {
      severity: 'error',
      summary: 'Forbidden',
      detail: 'Eek! Looks like you\'re in the no-fly zone. If this doesn\'t seem right, hit us up. We\'ll straighten things out.',
    },
    404: {
      severity: 'error',
      summary: 'Not found',
      detail: 'Uh-oh! That service seems to be hiding. Let\'s track it down together!',
    },
    408: {
      severity: 'error',
      summary: 'Timeout',
      detail: 'Hang tight! Your request got lost in cyberspace. Give it another shot, or check your internet connection if this keeps happening.',
    },
    500: {
      severity: 'error',
      summary: 'Server error',
      detail: 'Oops! Our bad. Something\'s gone wonky behind the scenes. We\'re hustling to fix it. Try again later, please.',
    },
    502: {
      severity: 'error',
      summary: 'Bad gateway',
      detail: 'Hmm, seems like a hiccup in the communication. Give us a moment to sort things out, then try again.',
    },
    503: {
      severity: 'error',
      summary: 'Service unavailable',
      detail: 'Our site\'s taking a breather for maintenance or it\'s just super popular right now. Check back in a bit!',
    },
    default: {
      severity: 'error',
      summary: 'Error',
      detail: 'Whoa, didn\'t see that coming! Try again, or give our support squad a shout for backup.',
    },
  };

  const toastQueue = ref([]);

  const addToToastQueue = (toastObject) => {
    // we add to the beginning so we can loop and remove fired toasts backwards
    toastQueue.value.unshift(toastObject);
  };

  const removeFromToastQueue = (index) => {
    toastQueue.value.splice(index, 1);
  };

  const makeErrorToast = (code, life = 5000) => {
    const message = errorMessagesByCode[code] || errorMessagesByCode.default;
    addToToastQueue({ ...message, life });
  };

  return {
    toastQueue,
    addToToastQueue,
    removeFromToastQueue,
    makeErrorToast,
  };
});

export default useToastsStore;
