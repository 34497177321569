import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useStorage } from '@vueuse/core';

import useUserService from '@/services/user';

const userService = useUserService();

export default defineStore('auth', () => {
  const savedState = useStorage(
    'tagboard-app',
    {
      currentTeamId: null,
    },
    localStorage,
    { mergeDefaults: true },
  );

  const currentTeamId = computed({
    get() {
      return savedState.value.currentTeamId;
    },
    set(value) {
      savedState.value.currentTeamId = value;
    },
  });

  const {
    data: user,
    execute: getAuth,
  } = userService.getAuth({ immediate: false });

  const userId = computed(() => user.value?.id);
  const userToken = computed(() => user.value?.token);
  const isAuthenticated = computed(() => !!user.value);
  const isStaff = computed(() => user.value?.groups?.includes('staff') ?? false);
  const isDesigner = computed(() => user.value?.groups?.includes('design') ?? false);
  const profile = computed(() => user.value?.profile);
  const accounts = computed(() => user.value?.otherAccounts);
  const facebookPages = computed(() => user.value?.facebook_pages || []);

  return {
    user,
    currentTeamId,
    getAuth,
    userId,
    userToken,
    isAuthenticated,
    isStaff,
    isDesigner, // Group for peeps who can manage presets
    profile,
    accounts,
    facebookPages,
  };
});
