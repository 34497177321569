// since we are outside of the component when this function is called from some
// of the services, we need to await the import of the store to prevent execution ordering errors
const onFetchError = async (payload) => {
  const { default: useToastsStore } = await import('@/stores/toasts');
  const { makeErrorToast } = useToastsStore();
  const { response, data, error } = payload;
  const code = data ? data.statusCode : error.code;

  if (error || !response?.ok) {
    /**
     * ignore abort errors
     * (trying to target child account login errors from refetch being cancelled when getting
     * team members and plan permissions as currentTeamId quickly changes)
     * Might be too heavy handed if we care about other aborts
     */
    if (error?.name === 'AbortError') return;

    makeErrorToast(code);
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export default onFetchError;
