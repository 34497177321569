import { createFetch } from '@vueuse/core';
import { computed } from 'vue';
import useErrorToasts from '@/composables/useErrorToasts';
import { PLANS_API } from '@/config/appConfig';

import useAuthStore from '@/stores/auth';

const plansFetch = createFetch({
  baseUrl: PLANS_API,
  options: {
    async onFetchError(payload) {
      useErrorToasts(payload);
      return payload;
    },
    beforeFetch({ options, cancel }) {
      const authStore = useAuthStore();

      if (!authStore.currentTeamId) cancel();
      // eslint-disable-next-line no-param-reassign
      options.headers['Tgb-Token'] = authStore.userToken;
    },
  },
  fetchOptions: {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  },
});

export default () => ({
  getTeams: (userId, fetchOptions = {}) => {
    const url = computed(() => `account/owners/${userId.value}`);

    return plansFetch(url, fetchOptions).get().json();
  },
  getTeamMembers: (userId, fetchOptions = {}) => {
    const url = computed(() => `account/admins/${userId.value}`);

    return plansFetch(url, fetchOptions).get().json();
  },
  removeTeamMember: (requestData, fetchOptions = {}) => {
    const url = '/account/admin';

    return plansFetch(url, fetchOptions).post({
      owner_id: requestData.ownerId,
      admin_id: requestData.adminId,
      operation: 'remove',
    }).json();
  },
  addTeamMember: (requestData, fetchOptions = {}) => {
    const url = '/account/admin';

    return plansFetch(url, fetchOptions).post({
      owner_id: requestData.ownerId,
      admin_id: requestData.adminId,
      operation: 'add',
    }).json();
  },
  getPlan: (fetchOptions = {}) => {
    const url = computed(() => '/plan/check');

    return plansFetch(url, fetchOptions).get().json();
  },
  inviteTeamMembers: (inviteData, fetchOptions = {}) => {
    const url = '/invite/multiple';

    return plansFetch(url, fetchOptions).post(inviteData).json();
  },
  getTeamInvites: (owner, fetchOptions = {}) => {
    const url = computed(() => `/invites/${owner.value}`);

    return plansFetch(url, fetchOptions).get().json();
  },
  resendInvite: (inviteId, fetchOptions = {}) => {
    const url = `/invite/${inviteId}/resend`;

    return plansFetch(url, fetchOptions).post({ invite_id: inviteId }).json();
  },
  cancelInvite: (inviteId, fetchOptions = {}) => {
    const url = `/invite/${inviteId}/cancel`;

    return plansFetch(url, fetchOptions).post({ invite_id: inviteId }).json();
  },

  /**
   * Get list of permissions by owner.
   * @param {Number} owner - ID of the owner of the account.
   * @param {Array<String>} permissions - List of permissions we want to fetch.
   * @param {Object} options - Additional fetch options.
   * @returns {Promise<Object>} API response. Map of requested permissions.
   */
  checkPlanPermissions: (owner, permissions = [], options = {}) => {
    const url = computed(() => `/plan/permissions/check?owner=${owner.value}&permissions=${permissions.join(',')}`);
    return plansFetch(url, options).get().json();
  },
});
