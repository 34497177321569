<script setup>
import {
  watch,
  watchEffect,
  onUnmounted,
} from 'vue';
import { RouterView } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useToast } from 'primevue/usetoast';

import Toast from 'primevue/toast';

import PageLoader from '@/components/PageLoader.vue';

import usePusher from '@/composables/usePusher';
import useToastsStore from '@/stores/toasts';
import useUserStore from '@/stores/user';

const pusher = usePusher();
const toast = useToast();

const { currentTeamId } = storeToRefs(useUserStore());
const { toastQueue, removeFromToastQueue } = useToastsStore();

const toastIconComponent = () => ({
  info: 'fa-regular fa-circle-info',
  success: 'fa-regular fa-check',
  warn: 'fa-regular fa-triangle-exclamation',
  error: 'fa-regular fa-circle-exclamation',
});

watchEffect(() => {
  if (toastQueue.length > 0) {
    for (let i = toastQueue.length - 1; i >= 0; i -= 1) {
      const toastMessage = toastQueue[i];
      toast.add(toastMessage);

      removeFromToastQueue(i);
    }
  }
});

watch(currentTeamId, async (newVal, oldVal) => {
  if (!newVal) return; // wait for user to be loaded

  // Clean up notifications for old user
  if (oldVal) {
    pusher.unsubscribeFromAdobeNotifications(oldVal);
    pusher.unsubscribeFromUserChannel(oldVal);
  }

  await pusher.init(newVal);
  pusher.subscribeToUserChannel(newVal);

  // the below comments were copied over from tgb-graphics
  // TODO: add permission check for user connection
  // and add smt to keep one connection per user between different opened tabs
  /*
  // Commenting out this "global" event binding until
  // we have a use for it as it bloats active connections (rveitch)
  pusher.subscribeToAdobeNotifications(newVal).bind('notification', ({ message }) => {
    // keep for global notifications
  });
  */
}, { immediate: true });

onUnmounted(() => {
  pusher.unsubscribeFromUserChannel(currentTeamId.value);
  pusher.unsubscribeFromAdobeNotifications(currentTeamId.value);
});
</script>

<template>
  <PageLoader />
  <RouterView />
  <Toast group="link">
    <template #message="slotProps">
      <font-awesome-icon :icon="toastIconComponent()[slotProps.message.severity]" />
      <div class="p-toast-message-text" data-pc-section="text"><span class="p-toast-summary" data-pc-section="summary">{{ slotProps.message.summary }}</span><div class="p-toast-detail" data-pc-section="detail">{{ slotProps.message.detail }} <a :href="slotProps.message.linkHref">{{ slotProps.message.linkText }}</a></div></div>
    </template>
  </Toast>
</template>
