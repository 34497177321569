<script setup>
import {
  watch,
} from 'vue';

import Toast from 'primevue/toast';
import DynamicDialog from 'primevue/dynamicdialog';
import ConfirmDialog from 'primevue/confirmdialog';

import useThemeSwitcher from '@/composables/useThemeSwitcher';

const { themePreference, applyTheme } = useThemeSwitcher();

watch(themePreference, (theme) => applyTheme(theme));
</script>

<template>
  <RouterView />
  <Toast />
  <DynamicDialog />
  <ConfirmDialog />
</template>

<style>
.page {
  margin:0;
}
</style>
